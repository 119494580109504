<template>
  <page-title-component title="Prompt Manager" />

  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-8">
              <button class="btn btn-sm btn-primary mr-2"
                      @click="$refs.promptFormModal.show()">Create Prompt</button>
            </div>
<!--            <div class="col-4">
              <input type="text"
                     placeholder="Search"
                     class="form-control">
            </div>-->
          </div>

          <hr>

          <table class="table table-hover table-striped table-bordered">
            <thead>
            <tr>
              <th>Prompt</th>
              <th class="text-right">Action</th>
            </tr>
            </thead>

            <tbody>
            <tr v-for="prompt in prompts" :key="'prompt_'+prompt.id">
              <td>{{ prompt.prompt }}</td>
              <td class="text-right">
<!--                <button class="btn btn-sm btn-info mr-2"
                        @click="$refs.promptDetailsModal.show(prompt)">
                  <i class="fa-solid fa-eye"></i>
                </button>-->

                <button class="btn btn-sm btn-danger"
                        @click="deleteRow(prompt)">
                  <i class="fa-solid fa-trash"></i>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <prompt-form-modal @added="loadPrompts" ref="promptFormModal" />
  <prompt-details-modal ref="promptDetailsModal" />
</template>
<script>
import PageTitleComponent from "@/components/PageTitleComponent.vue";
import PromptFormModal from "@/views/prompt_manger/PromptFormModal.vue";
import PromptDetailsModal from "@/views/prompt_manger/PromptDetailsModal.vue";
export default {
  components:{PageTitleComponent, PromptFormModal, PromptDetailsModal},
  data() {
    return {
      prompts: [],
    }
  },
  created() {
    this.loadPrompts();
  },
  methods: {
    loadPrompts() {
      this.prompts = [];

      this.axios.get('/prompts')
        .then(res => {
          this.prompts = res.data.data;
        })
    },
    deleteRow(row) {
      this.$swal({
        title: 'Are you sure want to delete?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgba(253,23,31,0.7)',
        confirmButtonText: 'Yes!',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.delete('/prompts/' + row.id)
            .then(() => {
              this.loadPrompts();
            });
        }
      })
    },
  }
}
</script>