<template>
  <div class="modal fade" id="prompt-form-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="campaign-modalLabel">Prompt</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <textarea rows="5"
                    class="form-control mt-2"
                    placeholder="Prompt"
                    v-model="form.prompt"></textarea>
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary btn-rounded my-2"
                  :disabled="fetchingEmail || !promptForm.email"
                  @click.prevent="submit">Save Prompt
          </button>

          <button class="btn btn-info btn-rounded my-2"
                  :disabled="fetchingEmail"
                  @click.prevent="fetchEmail">
            <template v-if="fetchingEmail">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Fetching...
            </template>
            <template v-else>
              Fetch Email
            </template>
          </button>
          <!--          <button type="button" class="btn btn-dark btn-rounded my-2" data-dismiss="modal">Close</button>-->
        </div>
        <div class="modal-body" v-if="promptForm.email">
          <div class="card">
            <div class="card-body bg-light">
              <p v-html="promptForm.email.replaceAll('\n', '<br>')"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fetchingEmail: false,
      modal: null,
      promptForm: {
        email: null
      },
      form: {
        prompt: '',
      }
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('prompt-form-modal'))
  },
  methods: {
    show() {
      this.promptForm.email = null;
      this.form.prompt = "";

      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    submit() {
      this.promptForm.prompt = this.form.prompt;

      this.axios.post('/prompts', this.promptForm)
          .then(() => {
            this.$emit('added');
            this.hide();
          })
          .finally(() => {
            this.fetchingEmail = false;
          })
    },
    fetchEmail() {
      this.promptForm.email = null;
      this.fetchingEmail = true;

      this.axios.post('/fetch-email', this.form)
          .then(res => {
            this.promptForm.email = res.data.data;
          })
          .finally(() => {
            this.fetchingEmail = false;
          })
    }
  }
}
</script>