<template>
  <div class="modal fade" id="prompt-details-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="campaign-modalLabel">Prompt</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" v-if="prompt">
          <div class="card">
            <div class="card-body bg-light">
              <p v-html="prompt.email.replaceAll('\n', '<br>')"></p>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-dark btn-rounded my-2" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      prompt: null,
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('prompt-details-modal'))
  },
  methods: {
    show(prompt) {
      this.prompt = prompt;
      this.modal.show();
    },
    hide() {
      this.modal.hide();
    }
  }
}
</script>